import * as React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router";

import Seo from "../components/seo";
import Layout from "../components/layout";

// import "../styles/contact.css";
import "../styles/freshwidget-custom.css";

// const EMAIL_PATTERN =
//   /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

const Contact = () => {
  const location = useLocation();

  const [iframeUrl, setIframeUrl] = React.useState(null);

  React.useEffect(() => {
    const getQueryParam = (param) => {
      const searchParams = new URLSearchParams(location.search);
      return searchParams.get(param);
    };

    const requesterEmail = getQueryParam("requester") ?? "";
    const subject = getQueryParam("subject") ?? "";
    const newIframeUrl = `https://petroninja.freshdesk.com/widgets/feedback_widget/new?&widgetType=embedded&formTitle=Help+%26+Support&submitTitle=Submit&submitThanks=Thank+you+for+your+feedback.&searchArea=no&helpdesk_ticket[requester]=${encodeURIComponent(
      requesterEmail
    )}&helpdesk_ticket[subject]=${encodeURIComponent(subject)}`;

    setIframeUrl(newIframeUrl);
  }, [location]);

  return (
    <>
      <Seo title="Contact" />
      <Helmet>
        <script
          type="text/javascript"
          src="https://s3.amazonaws.com/assets.freshdesk.com/widget/freshwidget.js"
        />
        {/* <style type="text/css" media="screen, projection">
          @import
          url(https://s3.amazonaws.com/assets.freshdesk.com/widget/freshwidget.css);
        </style> */}
      </Helmet>
      <Layout>
        <main className="max-w-container flex flex-col lg:flex-row space-y-10 py-20 px-10 lg:px-0 mx-auto">
          <div style={{ width: "100%" }}>
            {iframeUrl && (
              <iframe
                title="Feedback Form"
                id="freshwidget-embedded-form"
                height="480px"
                width="100%"
                src={iframeUrl}
              />
            )}
          </div>
          <div className="flex flex-col w-6/12 h-100 flex-1 lg:px-10 py-8 lg:border-l-2 lg:border-ecree sm:ml-40">
            <div className="mb-8">
              <strong>Address</strong>
              <a
                href="https://www.google.com/maps/place/639+5+Ave+SW,+Calgary,+AB+T2P+0M9/@51.0484849,-114.0777935,17z/data=!3m1!4b1!4m5!3m4!1s0x53716fe4eb0fff09:0xf7b1149afa3e1b6c!8m2!3d51.0484815!4d-114.0756048"
                target="_blank"
                rel="noreferrer"
                className="text-blue-500"
              >
                <div>639 5th Ave SW, Suite 1100</div>
                <div>Calgary, AB</div>
                <div>Canada, T2P 0M9</div>
              </a>
            </div>
            <div className="mb-8">
              <strong>Email</strong>
              <a
                href="mailto:hello@stackdx.com"
                target="_blank"
                rel="noreferrer"
                className="text-blue-500"
              >
                <div>hello@stackdx.com</div>
              </a>
            </div>
          </div>
        </main>
      </Layout>
    </>
  );
};

export default Contact;
